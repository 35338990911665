<template lang="pug">
.flex.flex-col.mt-2(class='w-2/3')
  span.font-semibold.text-headerText.text-sm.font-SourceSansPro {{ title === 1 ? $t("objects_tabs.extra.addNewField") : $t("objects_tabs.extra.changeField") }}
  span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mt-4 {{$t('name')}}
  .relative
    el-input(
      v-model='customField.name',
      type='text',
      :class='{ "input-error": isKeyError || isKeyAvailable }'
    )
    transition(name='el-zoom-in-top')
      .text-notify.text-xs.top-full.left-0.absolute(
        v-if='isKeyError || isKeyAvailable'
      ) {{ isKeyError ?  $t("objects.name") : $t("objects_tabs.sensors.keyExist")  }}
  span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mt-4 {{$t('value')}}
  .relative
    el-input(v-model='customField.value', type='text')
    transition(name='el-zoom-in-top')
      .text-notify.text-xs.top-full.left-0.absolute(v-if='isValueError') {{$t('objects_tabs.sensors.enterValue')}}
  .flex.justify-between.mt-5
    skif-button(@click='addCf') {{  title === 1 ? $t("create") : $t("change")  }}
    skif-button(variant='normal', @click='$emit("close")') {{ $t("cancel") }}
</template>

<script>
export default {
  props: {
    title: Number,
    cfToUpdate: {
      type: Object
    }
  },
  data() {
    return {
      isKeyAvailable: false,
      isKeyError: false,
      isValueError: false,
      customField: {
        name: '',
        value: ''
      }
    }
  },
  computed: {
    allCustomFields() {
      return this.$parent.trailer.custom_fields
    }
  },
  watch: {
    'customField.name': function (val) {
      this.isKeyError = val === ''
      this.isKeyAvailable = false
    },
    'customField.value': function (val) {
      this.isValueError = val === ''
    }
  },

  methods: {
    isKeyValid(key) {
      return this.allCustomFields.some(
        (k, index) =>
          k.key === key &&
          (index !== this.$parent.indexOfCf || this.title === 1)
      )
    },
    validCf() {
      let error = true
      if (this.customField.name === '') {
        this.isKeyError = true
        error = false
      } else if (this.isKeyValid(this.customField.name)) {
        this.isKeyAvailable = true
        error = false
      }
      if (this.customField.value === '') {
        this.isValueError = true
        error = false
      }
      return error
    },
    addCf() {
      if (this.validCf()) {
        this.$emit(this.title === 1 ? 'create' : 'update', {
          name: this.customField.name,
          key: this.customField.name,
          value: this.customField.value
        })
      }
    }
  },
  created() {
    if (this.title === 2)
      this.customField = JSON.parse(JSON.stringify(this.cfToUpdate))
  }
}
</script>
